<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="개정" class="cardClassDetailForm revisionLayout" topClass="topcolor-orange" v-if="popupParam.fireEquipmentId">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row">
                  <div class="col-1">
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정번호"
                      name="revisionNum"
                      v-model="data.revisionNum">
                    </c-text>
                  </div>
                  <div class="col-2">
                    <c-text
                      :editable="false"
                      label="개정일시"
                      name="regDtStr"
                      v-model="data.regDtStr">
                    </c-text>
                  </div>
                  <div class="col-1">
                    <c-text
                      :editable="false"
                      label="개정자"
                      name="regUserName"
                      v-model="data.regUserName">
                    </c-text>
                  </div>
                  <div class="col-4">
                    <c-moc 
                      :editable="editable"
                      :isSubmit="saveCallData"
                      :document.sync="data"
                      :documentId="data.fireEquipmentId"
                      documentTitle="installLocation"
                      mocRelatedTaskCd="RT00000001"
                      label="MOC번호"
                      name="sopMocId"
                      v-model="data.sopMocId"
                    />
                  </div>
                  <div class="col-4">
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정사유"
                      name="revisionContent"
                      v-model="data.revisionContent">
                    </c-text>
                  </div>
                </div>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="소화설비설치계획 상세정보" class="cardClassDetailForm">
            <template slot="card-select">
              <c-select
                :editable="!isRevision"
                v-if="popupParam.fireEquipmentId"
                :comboItems="gridrev.data"
                type="custom"
                typetext="개정이력"
                itemText="revisionNum"
                itemValue="fireEquipmentId"
                name="selectedfireEquipmentId"
                label=""
                v-model="selectedfireEquipmentId"
                @input="rowClickRev"
              ></c-select>
            </template>
            <template slot="card-button">
              <q-btn-group outline >
                <q-icon name="help" style="font-size: 20px; margin-right:10px;"
                  class="custom-btn text-primary">
                  <q-tooltip anchor="bottom left" self="top left">
                    <div class="tooltipCustomTop">
                      {{ $language('주석') }}
                    </div>
                    <div class="tooltipCustom">
                    {{ $language('① 설치지역별로 소화기 등 소화설비의 설치개수를 기재합니다.') }}<br>
                    {{ $language('② 스프링클러 등 수계소화설비는 Deluge(딜루지) 밸브 등의 설치개수를 기재합니다.') }}<br>
                    {{ $language('③ CO2 소화설비 등 가스계소화설비는 기동용기 등의 설치개수를 기재합니다.') }}<br>
                    {{ $language('④ ｢소방시설 설치･유지 및 안전관리에 관한 법률 시행령｣ 별표 1 및 ｢위험물안전관리법 시행규칙｣ 별표 17에 따라 분말소화설비 등 다른 형태의 소화설비를 추가하여 기재합니다.') }}<br>
                    {{ $language('⑤ 이 서식 대신 “소방시설등(기준･일람)산출표”를 사용할 수 있습니다.') }}<br>
                    {{ $language('※ 소화설비 용량산출 근거, 설계기준, 소화설비 계통도 등 제출') }}
                    </div>
                  </q-tooltip>
                </q-icon>
                <c-btn
                  v-show="editable && popupParam.fireEquipmentId && !isRevision"
                  label="개정"
                  icon="restart_alt"
                  @btnClicked="SetRevision" />
                <c-btn
                  v-show="editable && popupParam.fireEquipmentId && isRevision"
                  label="개정취소"
                  icon="restart_alt"
                  @btnClicked="CancelRevision" />
                <c-btn
                  v-if="editable && popupParam.fireEquipmentId"
                  label="삭제"
                  icon="remove"
                  @btnClicked="deleteData" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-3">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="설치지역"
                  name="installLocation"
                  v-model="data.installLocation">
                </c-text>
              </div>
              <div class="col-3">
                <c-plant
                  :required="true"
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-3">
                <c-process
                  :editable="editable"
                  label="관련공정"
                  multiple="single"
                  name="processCd"
                  v-model="data.processCd">
                </c-process>
              </div>
              <div class="col-3">
                <c-dept
                  :editable="editable"
                  label="관리부서"
                  name="deptCd"
                  v-model="data.deptCd">
                </c-dept>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="소화기"
                  name="fireExtinguisher"
                  v-model="data.fireExtinguisher">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="자동확산소화기"
                  name="autoSpreadFire"
                  v-model="data.autoSpreadFire">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="자동소화장치"
                  name="autoFire"
                  v-model="data.autoFire">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="옥내소화전"
                  name="standPipe"
                  v-model="data.standPipe">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="스프링쿨러"
                  name="sprinkler"
                  v-model="data.sprinkler">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="물분부소화설비"
                  name="waterSpray"
                  v-model="data.waterSpray">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="포소화설비"
                  name="poSpray"
                  v-model="data.poSpray">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="CO2소화설비"
                  name="co2Spray"
                  v-model="data.co2Spray">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="할로겐화합물소화설비"
                  name="halogenSpray"
                  v-model="data.halogenSpray">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="청정소화약제소화설비"
                  name="cleanSpray"
                  v-model="data.cleanSpray">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="옥외소화전"
                  name="outdoorPipe"
                  v-model="data.outdoorPipe">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'fire-equipment-detail',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        fireEquipmentId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      saveCallData: '',
      selectedfireEquipmentId: null,
      saveUrl: transactionConfig.psi.lbe.fireequip.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      isSave: false,
      isdelete: false,
      isRevision: false,
      data: {
        fireEquipmentId: '',  // 소화설비설치계획 일련번호
        plantCd: '',  // 사업장코드
        installLocation: '',  // 설치지역
        fireExtinguisher: '',  // 소화기
        autoSpreadFire: '',  // 자동확산소화기
        autoFire: '',  // 자동소화장치
        standPipe: '',  // 옥내소화전
        sprinkler: '',  // 스프링쿨러
        waterSpray: '',  // 물분부소화설비
        poSpray: '',  // 포소화설비
        co2Spray: '',  // CO2소화설비
        halogenSpray: '',  // 할로겐화합물소화설비
        cleanSpray: '',  // 청정소화약제소화설비
        outdoorPipe: '',  // 옥외소화전
        processCd: '',
        deptCd: '',
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        regDtStr: '',
        regUserName: '',
        sopMocId: ''
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      gridrev: {
        columns: [
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: '개정번호',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'revisionContent',
            field: 'revisionContent',
            label: '개정사유',
            align: 'left',
            sortable: true,
          },
          {
            name: 'regDtStr',
            field: 'regDtStr',
            label: '개정일시',
            style: 'width: 150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            label: '개정자',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.psi.lbe.fireequip.get.url;
      this.insertUrl = transactionConfig.psi.lbe.fireequip.insert.url;
      this.updateUrl = transactionConfig.psi.lbe.fireequip.update.url;
      this.deleteUrl = transactionConfig.psi.lbe.fireequip.delete.url;
      this.revlistUrl = selectConfig.psi.lbe.fireequip.list.url + '/revs';
      this.getData();
    },
    getData() {
      this.isRevision = false;
      if (this.popupParam.fireEquipmentId) {
        this.getDetail(this.popupParam.fireEquipmentId, true);
        this.selectedfireEquipmentId = this.popupParam.fireEquipmentId
      }
    },
    getDetail(_fireEquipmentId, _check) {
      this.$http.url = this.$format(this.detailUrl, _fireEquipmentId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.data.regDtStr = this.data.regDtStr.slice(0, 19)
        // 상세팝업 최초작성/최근수정 정보 노출
        this.$emit('setRegInfo', _result.data)
        this.isRevision = false;
        if (_check) {
          this.getRevList(_result.data.groupId);
        }
        if(_result.data.fireEquipmentId == this.popupParam.fireEquipmentId) {
          this.editable = this.$route.meta.editable;
        } else {
          this.editable = false;
        }
      },);
    },
    saveData() {
      if (this.popupParam.fireEquipmentId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      let saveMsg = '저장하시겠습니까?';  
      if (this.isRevision) {
        saveMsg = '현재버전을 개정하여 저장하시겠습니까?';
        // 현재버전을 개정하여 저장하시겠습니까?
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인', 
            message: saveMsg,
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              // 개정시
              if (this.isRevision) {
                this.saveUrl = this.insertUrl;
                this.mappingType = 'POST';
                this.data.sysRevision = parseInt(this.data.sysRevision) + 1;
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인', 
        message: '삭제하시겠습니까?',
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.fireEquipmentId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.fireEquipmentId = result.data.fireEquipmentId
      this.saveCallData = uid();
      this.getData();
    },
    SetRevision() {
      this.$http.url = selectConfig.com.revision.getversion;
      this.$http.param = {revisionNum: this.data.revisionNum}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data.revisionNum = _result.data;
        this.data.revisionContent = '';
        this.data.regDtStr = '';
        this.data.regUserName = '';
        this.data.sopMocId = '';
        this.isRevision = true;
      });
    },
    CancelRevision() {
      this.isRevision = false;
      this.getData();
    },
    getRevList(_groupId) {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {groupId: _groupId};
      this.$http.request((_result) => {
        this.gridrev.data = _result.data;
      },);
    },
    rowClickRev(row) {
      if (row) {
        this.selectedfireEquipmentId = row;
        if (this.selectedfireEquipmentId != this.data.fireEquipmentId) {
          this.getDetail(this.selectedfireEquipmentId, false);
        }
      }
    },
  }
};
</script>
